<template>
  <div>
    <!-- <b-row v-if="!visibleInputs" class="animate__animated animate__fadeIn">
      <b-col cols="3">
        <b-button
          @click="visibleInputs = true"
          block
          class="float-left"
          variant="warning"
        >
          See Inputs
        </b-button>
      </b-col>
    </b-row> -->
    <b-collapse
      visible
      id="input-collapse"
      class="mt-2"
      v-model="visibleInputs"
    >
      <b-overlay :show="loading" rounded="sm" no-fade>
        <b-card>
          <div>
            <h4 class="text-center" v-if="allInputs.length == 0">
              {{'No input data found, please insert new input values'}}
            </h4>
          </div>
          <validation-observer ref="titleRules" v-if="allInputs.length > 0">
            <b-form>
              <b-row class="mb-2 mt-1" align-h="center">
                <b-col cols="12" md="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Title"
                    style="width: 100%;"
                    rules="required"
                  >
                    <b-form-group label-for="title">
                      <b-form-input
                        id="title"
                        type="text"
                        v-model="calculateData.calculateName"
                        :state="errors.length > 0 ? false : null"
                        :trim="true"
                        placeholder="Calculate Title"
                        aria-describedby="input-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col
                  cols="4"
                  md="4"
                  sm="12"
                  v-for="(input, index) in allInputs"
                  :key="index"
                >
                <validation-provider
                    #default="{ errors }"
                    name="Input"
                    rules="positive"
                    style="width: 100%;"
                  >
                  <b-form-group
                    :label="input.alias.toUpperCase()"
                    label-for="input"
                    class="label-font"
                  >
                    <b-form-input
                      :id="input.alias"
                      v-model.number="input.value"
                      type="number"
                      :placeholder="input.alias"
                      @change="controlZeroVariable(input.value)"
                      debounce="500"
                      :trim="true"
                      aria-describedby="input-live-feedback"
                    ></b-form-input>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-if="input.value == 0">{{ 'Value cannot be zero(0)' }}</small>

                  </validation-provider>
                </b-col>
              </b-row>

              <b-row align-h="center" class="mt-2">
                <b-col cols="4" md="4" sm="12">
                  <b-button
                    @click="saveCalculate()"
                    block
                    class="float-right"
                    :variant="checkAllInput ? 'success' : 'warning'"
                    :disabled="btnLoading"
                  >
                    <b-spinner small type="grow" v-if="btnLoading"></b-spinner>
                    {{
                      checkAllInput
                        ? "Save for Calculate"
                        : "Draft for Calculate"
                    }}
                  </b-button>

                  <div class="text-center mt-4">
                    <h6 class="text-danger" v-if="seeCustomInputError"
                      >All inputs must be entered in order to see the
                      results.Your data is saved as draft</h6
                    >
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-overlay>
    </b-collapse>
    <!-- <b-collapse id="output-collapse" class="mt-2" v-model="visibleResults">
      <ResultOutput :resultId="resultId" />
    </b-collapse> -->
  </div>
</template>

<script>
import {
  BImg,
  BPagination,
  BFormRating,
  BOverlay,
  BCard,
  BFormGroup,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  VBToggle,
  BCollapse,
} from "bootstrap-vue";

import axiosIns from "@/libs/axios";
import store from "@/store";
import router from "@/router";
import { onUnmounted } from "@vue/composition-api";
import formulStoreModule from "../formulStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, alpha, positive } from "@validations";
import moment from "moment";
import _ from "lodash";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import "animate.css";

import ResultOutput from "./Calculate.vue";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormGroup,
    BPagination,
    BFormRating,
    BCollapse,
    moment,
    vSelect,
    flatPickr,
    ToastificationContent,
    ResultOutput,

    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const FORMUL_APP_STORE_MODULE_NAME = "formul";
    // Register module
    if (!store.hasModule(FORMUL_APP_STORE_MODULE_NAME))
      store.registerModule(FORMUL_APP_STORE_MODULE_NAME, formulStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORMUL_APP_STORE_MODULE_NAME))
        store.unregisterModule(FORMUL_APP_STORE_MODULE_NAME);
    });
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      calculateData: {
        calculateName: null,
        status: "A",
        values: [],
      },
      loading: false,
      btnLoading: false,
      allInputs: [],
      visibleInputs: true,
      visibleResults: false,
      resultId: 0,
      seeCustomInputError: false,
      inputValidated:true
    };
  },
  mounted() {
    this.getInputs();
  },
  computed: {
    checkAllInput() {
      if (
        this.allInputs.find(
          (o) => o.value == null || this.allInputs.find((o) => o.value == "")
        )
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    getInputs() {
      this.loading = true;

      this.$store
        .dispatch("formul/fetchAllInputs")
        .then((res) => {
          this.allInputs = [];
          if (res.data.length > 0) {
            res.data.forEach((d) => {
              d.value = null;
            });
          }
          this.allInputs = res.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log("error get all inputs data ", error);
        });
    },
    saveCalculate() {
      this.calculateData.values = [];

      this.$refs.titleRules.validate().then((success) => {
        if (success && this.inputValidated) {
          this.btnLoading = true;

          this.calculateData.status = this.checkAllInput ? "B" : "A";
          this.allInputs.forEach((input) => {
            this.calculateData.values.push({
              id: input.id,
              codeName: input.codeName,
              alias: input.alias,
              value: input.value,
            });
          });

          this.$store
            .dispatch("formul/createCalculateVariables", this.calculateData)
            .then((res) => {
              if (this.checkAllInput) {
                this.resultId = res.data.id;
                this.visibleResults = true;
                this.visibleInputs = false;
                this.toastMessage("Input variables");
                // this.seeCustomInputError = false;
                router.push({
                    name: 'input-detail',
                    params: { id: res.data.id },
                });
              } else {
                // this.seeCustomInputError = true;
                this.toastDraftMessage("Input variables")
                router.push({
                    name: 'input-detail',
                    params: { id: res.data.id },
                });
              }
              this.btnLoading = false;
            })
            .catch((error) => {
              this.btnLoading = false;
              console.log("error create data ", error);
            });
        }
      });
    },
    toastDraftMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successful",
          text: "✔️ " + val + " Added Draft Successful",
          icon: "ThumbsUpIcon",
          variant: "warning",
        },
      });
    },
    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successful",
          text: "✔️ " + val + " Added Successful",
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    checkAllInputsToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text:
            "All inputs must be entered in order to see the results.Your data is saved as draft",
          icon: "AlertCircleIcon",
          variant: "warning",
        },
      });
    },
    controlZeroVariable(v) {
      if(v <= 0) {
        this.inputValidated = false
      } else {
        this.inputValidated = true
      }
    },
  },
  watch: {
    // allInputs: {
    //   handler: function(val, before) {
    //     console.log(val)
    //   },
    // },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style>
.label-font label {
  font-size: 1.2rem !important;
  font-weight: bold;
}
</style>
